/** @jsx jsx */
import { jsx, Flex, Styled, Grid, Button} from "theme-ui"
import React, { useState, useContext, useEffect, useCallback } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {useStaticQuery, graphql, Link} from "gatsby"
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import Img from "gatsby-image"
import Stars from "../../images/stars.png"
import { StoreContext} from '../../context'

export const QuizContainer = () => {

  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    sleeptype: "",
    temperature: "",
    comfort: "",
    room: "",
    aches: "",
    persona: "",
    email: "",
    height: "",
    weight: "",

  })
  
  const [commentary, setCommentary] = useState(
    {
      size: "",
      sleep_position: "",
      infusion: "",
      review: "",
    }
  );

  const stepCount = 3
  const [step, setStep] = useState(0);
  const [color,setColor] = useState("#81d4fa");
  const [ scroll, setScroll] = useState(false)
  const [position, setPosition] = useState(0)

  
  const handleChange = (event) => {
    const{name, value} = event.target
    setValues({...values, [name]: value})
    next();
  }




  useEffect(() => {
    window.addEventListener("scroll", onScroll)
  },[])

  const onScroll = () => {
    const newPosition = window.scrollY
    if (newPosition > position) {
      setScroll(true)
    } else if (newPosition < position) {
      setScroll(false)
    } else if (newPosition === position) {
      setScroll(true)
    }

    setPosition(window.scrollY)

  }


  const next = () => {
    let currentStep = step
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= stepCount ? stepCount + 1 : currentStep + 1
    setStep(currentStep)
  }
    

const data = useStaticQuery(graphql`
  query {
    product: allShopifyProduct {
      nodes{
        id
        title
        handle
        productType
        description
        descriptionHtml
        shopifyId
        options {
          id
          name
          values
        }
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          selectedOptions {
            name
            value
          }
          image {
            originalSrc
            id
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 910) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        images {
          originalSrc
          id
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 910) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`)

const product = data.product.nodes[0]

const {
  options,
  variants,
  variants: [initialVariant],
  priceRange: { minVariantPrice },
} = product


const [variant, setVariant] = useState({ ...initialVariant })
const [quantity, setQuantity] = useState(1)

const { addVariantToCart, store: { client, adding}} = useContext(StoreContext)

const productVariant =
  client.product.helpers.variantForOptions(product, variant) || variant
const [available, setAvailable] = useState(productVariant.availableForSale)

const checkAvailability = useCallback(
  productId => {
    client.product.fetch(productId).then(() => {
      // this checks the currently selected variant for availability
      const result = variants.filter(
        variant => variant.shopifyId === productVariant.shopifyId
      )
      setAvailable(result[0].availableForSale)
    })
  },
  [client.product, productVariant.shopifyId, variants]
)

useEffect(() => {
  checkAvailability(product.shopifyId)
}, [productVariant, checkAvailability, product.shopifyId])

const handleQuantityChange = ({ target }) => {
  setQuantity(target.value)
}

const handleOptionChange = (optionIndex, { target }) => {
  const { value } = target
  const currentOptions = [...variant.selectedOptions]

  currentOptions[optionIndex] = {
    ...currentOptions[optionIndex],
    value,
  }

  const selectedVariant = find(variants, ({ selectedOptions }) =>
    isEqual(currentOptions, selectedOptions)
  )

  setVariant({ ...selectedVariant })
  optionColor(value);
  optionCommentary(value);
  next();
}


const handleAddToCart = () => {
  addVariantToCart(productVariant.shopifyId, quantity)
}

const checkDisabled = (name, value) => {
  const match = find(variants, {
    selectedOptions: [
      {
        name: name,
        value: value,
      },
    ],
  })
  if (match === undefined) return true
  if (match.availableForSale === true) return false
  return true
}

const price = Intl.NumberFormat(undefined, {
  currency: minVariantPrice.currencyCode,
  minimumFractionDigits: 2,
  style: 'currency',
}).format(variant.price)

const questionValue = (value) => {
  switch(value) {
    case 'Size':
      return 'My bed is a';
    case 'Sleep Type':
      return 'I sleep on my';
    case 'Infusion':
      return 'I wake up';
    default:
      return value;
  }
}

const optionValue = (value) => {
  switch(value) {
    case 'Gel':
      return 'Hot';
    case 'CBD':
      return 'Restless';
    case 'Charcoal':
      return 'Sweaty';
    case 'Peppermint':
      return 'Groggy';
    case 'Lavender':
      return "Sore";
    case 'Chamomile':
      return "Tired";
    default:
      return value;
  }
}

const optionColor = (value) => {
    if (value === "Gel") {
      setColor("#81d4fa");
    } else if (value === "CBD") {
      setColor("#a5d6a7");
    } else if (value === "Charcoal") {
      setColor("#eeeeee");
    } else if (value === "Peppermint") {
      setColor("#80cbc4");
    } else if (value === "Lavender") {
      setColor("#ce93d8");
    } else if (value === "Chamomile") {
      setColor("#fff59d");
    }
}

const optionCommentary = (value) => {
  if (value === "Queen") {
    setCommentary({...commentary, ["size"]: "Great! The dimensions of your pillow are 29”x16”. This size works great for Twin, Full, and Queen size mattresses."})
  } else if (value === "King") {
    setCommentary({...commentary, ["size"]: "Great! The dimensions of your pillow are 35”x16”. This size works great for King and Cal King size mattresses"})
  }else if (value === "Side") {
    setCommentary({...commentary, ["sleep_position"]: "You’re not alone! 68% of people sleep on their side. You need our Reactiv Foam with a Shoulder Cut Out that keeps your head aligned and eliminates the neck gap to give you great support."})
  } else if (value === "Back") {
    setCommentary({...commentary, ["sleep_position"]: "Health experts say this is the healthiest position to sleep in! It is easy for your head, neck, and spine to align and stay in a neutral position. Our Reactiv Foam ensures no extra pressure or curves are being added to the back. "})
  } else if (value === "Stomach") {
    setCommentary({...commentary, ["sleep_position"]: "This is the toughest position to find proper spine support and alignment, but we have a great solution! Our Plush Reactiv Foam still gives you the support you need without being as firm as traditional foam that would cause kinks in your neck."})
  } else if (value === "Gel") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with cooling Gel to dissipate heat and to help keep your skin temp between 87-90 degrees!"})
  } else if (value === "CBD") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with CBD Oil that promotes a deeper and more sound sleep."})
  } else if (value === "Charcoal") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with Bamboo Charcoal to help reduce moisture, odors and keep your pillow clean."})
  } else if (value === "Peppermint") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with Peppermint Essential Oil that helps with mental clarity and releases tension."})
  } else if (value === "Lavender") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with real Lavender Essential Oil that is known for its soothing properties and naturally promotes relaxation."})
  } else if (value === "Chamomile") {
    setCommentary({...commentary, ["infusion"]: "We infuse our foam with Chamomile Essential Oil that is known as a natural sedative and helps you fall asleep. "})
  } else {
    setCommentary({...commentary, ["review"]: "Oh my goodness I just love this pillow it is so comfortable I use it sometimes as a back rest when I am watching tv it is such a great pillow, it did have that funny smell when you first open it up but that goes away in a couple of days. Finally getting a good night sleep. Even my smaller dog wants to sleep on it I have to keep shoeing her off it . Not disappointed at all i Love it."})
  }

}




  return (

    <>

    <Flex sx={{alignItems: "center", flexWrap: "wrap", width: "100%", py: [0,0,6]}}>
      <div sx={{width: ["100%","100%","40%"], backgroundColor: color}}>
        <Img fluid={variant.image.localFile.childImageSharp.fluid} className="pillow-slide"  />
      </div>
      <Flex sx={{justifyContent: "center", width: ["100%","100%","50%"], px: [5,5,4], pt: 4}}>
        <div sx={{width: ["100%","100%","50%"]}}>
         
          <Flex sx={{flexDirection: "column"}}>

            {step === stepCount && (
              <>
                <Styled.h1 sx={{mb: 1}}>We found your perfect pillow!</Styled.h1>
                <Flex sx={{justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                  <Styled.h6 sx={{mb: 0}}>It is the <span sx={{fontWeight: "heading"}}>{variant.title}</span> pillow for <span sx={{fontWeight: "heading"}}>{price}</span></Styled.h6>
                </Flex>
                </>
            )}

            {step !== stepCount && (
              <>
              <Styled.h1>Let's find your perfect pillow!</Styled.h1>
              <img src={Stars} width="100px" />
              <Styled.p sx={{mb: 0}}>"I will never buy another pillow. Our whole family now uses these pillows. The perfect combination of comfort and firmness."</Styled.p>
              <Styled.p sx={{fontWeight: "heading", fontSize: "10px"}}>Reviewed by Jonathon M</Styled.p>
              <div sx={{width: "100%"}}>
                <Flex sx={{width: "100%", alignItems: "center", justifyContent: "center"}} >
                  <progress id="file" max="100" sx={{width: "100%"}} value={step/stepCount * 100}> {step/stepCount * 100} </progress>
                </Flex>
              </div>
              </>
            )}


            <TransitionGroup component={null}>
              {commentary.review !== "" && (
                <CSSTransition classNames="slide" timeout={1000}>
                  <img src={Stars} width="150px" />
                  <Styled.p>{commentary.review}</Styled.p>
                </CSSTransition>

              )}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {commentary.size !== "" && (
                <CSSTransition classNames="slide" timeout={1000}>
                  <Styled.p>{commentary.size}</Styled.p>
                </CSSTransition>

              )}
            </TransitionGroup>

            <TransitionGroup component={null}>
              {commentary.sleep_position !== "" && (
                <CSSTransition classNames="slide" timeout={1000}>
                  <Styled.p>{commentary.sleep_position}</Styled.p>
                </CSSTransition>

              )}
            </TransitionGroup>


            <TransitionGroup component={null}>
              {commentary.infusion !== "" && (
                <CSSTransition classNames="slide" timeout={1000}>
                  <>
                  <Styled.p>{commentary.infusion}</Styled.p>
                  <Flex sx={{justifyContent: "flex-end", mt: 4}}>
                    <Styled.a as={Link} to="/products/reactiv-foam-pillow" sx={{fontSize: "10px"}}>Show me all pillows →</Styled.a>
                  </Flex>
                  </>
                </CSSTransition>

              )}
            </TransitionGroup>

            

            
             
             
            <Flex sx={{width: "100%", flexDirection: "column", justifyContent: "center"}}>
              {options.map((option, i) => (
                <TransitionGroup component={null}>
                  {step === i && (
                    <CSSTransition classNames="slide" timeout={1000}>
                        <div>
                          <Styled.h6 sx={{mb: 4, fontWeight: "heading", fontSize: 0}}>{questionValue(option.name)}</Styled.h6>
                          <Grid columns={3} gap={2} >
                            {option.values.map(value => {
                              return (
                                <>
                                  <input
                                    sx={{display: "none"}}
                                    className="checkbox"
                                    id={value}
                                    name={option.name}
                                    type="radio"
                                    value={value}
                                    onChange={event => handleOptionChange(i, event)}
                                  />
                                  <label htmlFor={value} sx={{width: "100%", textAlign: "center", backgroundColor: "light"}}>
                                    <Flex sx={{flexDirection: "column", alignItems: "center"}}>
                                      <span>{optionValue(value)}</span>
                                    </Flex>
                                  </label>
                                </>
                              )
                            })}
                          </Grid>
                        </div>
                    </CSSTransition>
                  )}
                </TransitionGroup>
              ))}  

              <TransitionGroup component={null}>
                {step === stepCount && (
                  <CSSTransition classNames="slide" timeout={1000}>
                    <>
                    <div className={scroll ? 'show' : 'hide'} sx={{backgroundColor: "white", p: 4, position: "fixed", bottom: "0", left: "0", right: "0", width: "100%", zIndex: "3000",  display: ["flex", "flex","flex", "none"]}}>
                      <Flex sx={{width: "100%",justifyContent: "space-between", alignItems: "stretch"}}>
                        <Flex>
                          <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            min="1"
                            step="1"
                            onChange={handleQuantityChange}
                            value={quantity}
                            sx={{border: theme => `1px solid ${theme.colors.gray}`, textAlign: "center", width: "100px"}}
                          />
                        </Flex>
                        <Button
                          type="submit"
                          disabled={!available || adding}
                          onClick={handleAddToCart}
                          variant="primary"
                          sx={{maxWidth: "100%", width: "100%", display: "flex", ml: 6, justifyContent: "center"}}
                        >
                          {!available ? "Out of Stock!" : adding ? "Adding to cart..." : "Add to Cart →"}
                        </Button>
                        {!available && <p>This Product is out of Stock!</p>}
                      </Flex>
                    </div>
                    <div sx={{display: ["none", "none", "none","block"]}}>
                      <div>
                        <Styled.h5 sx={{mb: 2, fontWeight: "heading"}}>Quantity</Styled.h5>
                        <Flex sx={{alignItems: "stretch"}}>
                          <Flex>
                            <input
                              type="number"
                              id="quantity"
                              name="quantity"
                              min="1"
                              step="1"
                              onChange={handleQuantityChange}
                              value={quantity}
                              sx={{border: theme => `1px solid ${theme.colors.gray}`, textAlign: "center", width: "100px"}}
                            />
                          </Flex>
                          <Button
                            type="submit"
                            disabled={!available || adding}
                            onClick={handleAddToCart}
                            variant="primary"
                            sx={{maxWidth: "100%", width: "100%", display: "flex", ml: 6, justifyContent: "center"}}
                          >
                            {!available ? "Out of Stock!" : adding ? "Adding to cart..." : "Add to Cart →"}
                          </Button>
                          {!available && <p>This Product is out of Stock!</p>}
                        </Flex>
                      </div>
                    </div>
                    </>
                  </CSSTransition>
                )}
              </TransitionGroup>     
            </Flex>

          </Flex>
        </div>
      </Flex>
    </Flex>




  </>
  )
} 
