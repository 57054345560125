/** @jsx jsx */
import { jsx, Button, Grid, Styled, Label, Flex, Box} from 'theme-ui'
import { useState, useContext, useEffect, useCallback } from 'react'
import {Link} from 'gatsby'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import {VariantSelector} from "./"

import { StoreContext,SiteContext } from '../../context'

export const Form = ({ product }) => {
  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)

  const { addVariantToCart, store: { client, adding}} = useContext(StoreContext)
  const {site: { showGuide }, toggleGuide} = useContext(SiteContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)

  const checkAvailability = useCallback(
    productId => {
      client.product.fetch(productId).then(() => {
        // this checks the currently selected variant for availability
        const result = variants.filter(
          variant => variant.shopifyId === productVariant.shopifyId
        )
        setAvailable(result[0].availableForSale)
      })
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, { target }) => {
    const { value } = target
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = () => {
    addVariantToCart(productVariant.shopifyId, quantity)
  }

  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)

  return (

    <div  sx={{px: [4], pb: 6}}>
      <Flex sx={{justifyContent: "flex-start", alignItems: "flex-end"}}>
        <Styled.h4 sx={{mr: 4}}>{ product.title }</Styled.h4>
        <Styled.h5>{price}</Styled.h5>
      </Flex>

      <div className="yotpo bottomLine"
          data-appkey="U0bo8JJ0jtjJNZajDWS8DyPssp19aLboUd8ZSLRH"
          data-domain="sleep-n-snooze.myshopify.com"
          data-product-id={product.id}
          data-product-models={product.id}
          data-name={product.title}
          data-url={`sleep-n-snooze.myshopify.com/products/${product.handle}`}
          data-image-url={product.images[0].localFile.url}
          data-description={product.description}>
      </div>

        {options.map((option, i) => (
          <VariantSelector key={i} option={option} onChange={event => handleOptionChange(i, event)} />
        ))}

        {/* {options.map(({name, values }, index) => (
          <React.Fragment key={index}>
              
            <Flex sx={{justifyContent: "space-between", mt: 5}}>
              <Styled.h5 sx={{mb: 2, mt: 2, textTransform: 'uppercase'}}>{name}</Styled.h5>
            </Flex>
            <Grid columns={[3]}>
            {values.map((value, i) => (
              <Box sx={{width: "100%"}}>
                  <input
                    type="radio"
                    name={name}
                    sx={{display: "none", '&:checked ~ label': {border: theme => `1px solid ${theme.colors.dark}`}}}
                    id={value}
                    onClick={event => handleOptionChange(index, event)}
                    value={value}
                    key={`${name}-${value}`}
                    disabled={checkDisabled(name, value)}
                  />
                <Label htmlFor={value} sx={{p: 4, border: theme => `1px solid ${theme.colors.gray}`}}>
                  <span sx={{textAlign: "center", width: "100%"}}>{value}</span>
                  <span sx={{pl: 4, color: "gray"}}>{checkDisabled(name, value) ? 'Sold Out' : ''}</span>
                </Label>
              </Box>
            ))}
            </Grid>

            <br />
          </React.Fragment>
        ))} */}
        <Styled.h5 sx={{mb: 2}}>Quantity</Styled.h5>
        <Flex sx={{alignItems: "stretch"}}>
          <Flex>
            <input
              type="number"
              id="quantity"
              name="quantity"
              min="1"
              step="1"
              onChange={handleQuantityChange}
              value={quantity}
              sx={{border: theme => `1px solid ${theme.colors.gray}`, textAlign: "center", width: "100px"}}
            />
          </Flex>
          <Button
            type="submit"
            disabled={!available || adding}
            onClick={handleAddToCart}
            variant="primary"
            sx={{maxWidth: "100%", width: "100%", display: "flex", ml: 6, justifyContent: "center"}}
          >
            {!available ? "Out of Stock!" : adding ? "Adding to cart..." : "Add to Cart"}
          </Button>
          {!available && <p>This Product is out of Stock!</p>}
        </Flex>


      </div>
  )
}
