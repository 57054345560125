/** @jsx jsx */
import { jsx, Select } from 'theme-ui'
import React from "react"
export const VariantSelector = ({option, onChange}) => {

  return (
    <>
      <label htmlFor={option.name} >{option.name}</label>

      <div sx={{position: "relative"}}>
        <Select 
          sx={{py: 3, px: 4, width: "100%"}}
          id={option.name} 
          name={option.name}
          key={option.id}
          onChange={onChange}
          >
          
          {option.values.map(value => {
            return (
              <option
                value={value}
                key={`${option.name}-${value}`}
              >{`${value}`}</option>
            )
          })}
        </Select>
      </div>
      <br/>
    </>
  )
}
