/** @jsx jsx */
import { jsx, Styled, Flex, Container, Grid } from "theme-ui"

import { QuizContainer } from "../../components/PillowQuiz"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/landingLayout"
import SEO from "../../components/seo"

import Plush from '../../images/plush.mp4'
import Support from '../../images/support.mp4'
import Squishy from '../../images/squishy.mp4'

import Comfort from '../../images/comfort.svg'
import Temperature from '../../images/temperature.svg'
import Alignment from '../../images/spine.svg'

import "../../styles/swiper.css"


const IndexPage = () => {


  const {healthline, huffpost, sleep} = useStaticQuery(graphql`
    query {
      healthline: file(relativePath: { eq: "healthline-black.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      huffpost: file(relativePath: { eq: "huffpost.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sleep: file(relativePath: { eq: "sleep-foundation.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)




  return(
    <Layout>
      <SEO title="Pillow Quiz" description="Find your perfect pillow!" />
      <div sx={{backgroundColor: "#fff", width: "100%"}}>
         <QuizContainer />
      </div>

    {/* Social Proof */}
      <div sx={{backgroundColor: "#f6f6f4", width: "100%", py: 6}}>
        <Container sx={{px: [5, 5, 0]}}> 
          <Grid columns={[1,1,3]} gap={6}>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
              <Img fluid={healthline.childImageSharp.fluid} sx={{width: "150px"}}/>
              <Styled.p sx={{fontSize: 1, textAlign: "center"}}>
              “A good night’s sleep is incredibly important for your health. In fact, it’s just as important as eating healthy and exercising.”
              </Styled.p>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
              <Img fluid={huffpost.childImageSharp.fluid} sx={{width: "150px"}}/>
              <Styled.p sx={{fontSize: 1, textAlign: "center"}}>
              “A good pillow is critical. If you’re sleeping on the wrong pillow your whole body is out of alignment”
              </Styled.p>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center"}}>
              <Img fluid={sleep.childImageSharp.fluid} sx={{width: "100px"}}/>
              <Styled.p sx={{fontSize: 1, textAlign: "center"}}>
              “When you sleep, your pillow should support your head and neck, allowing them to stay in a neutral position while you sleep.”
              </Styled.p>
            </Flex>
          </Grid>
        </Container>
      </div>

    {/* Features */}
      <div sx={{backgroundColor: "#fff", width: "100%", py: 6}}>
        <Container> 
            <Flex sx={{flexWrap: "wrap", justifyContent: "center",alignItems: "center", width: "100%"}}>
              <Flex sx={{width: ["100%","100%","40%"], justifyContent: "center"}}>
                <video width="100%" height="auto" autoPlay muted loop playsInline>
                  <source src={Plush} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Flex>
              <div sx={{width: ["100%","100%","40%"],px: 5}}>
                <Styled.h2 sx={{mt: [6,6,0]}}>Comfort</Styled.h2>
                <Styled.p sx={{fontSize: 1}}>
                Finding a supportive pillow isn’t enough, it also needs to be comfortable, both in feel and sleep temperature. We provide pillows for all sleep positions to help you sleep comfortably 
                no matter what position you are in. To keep you sleeping comfortably at the right temperature we ventilate our pillows to give you plenty of airflows.
                </Styled.p>
              
              </div>
            </Flex>
          </Container>
        </div>
        <div sx={{backgroundColor: "lightGray", width: "100%", py: 6}}>
        <Container>
          <Flex sx={{flexWrap: "wrap", justifyContent: "center",alignItems: "center"}}>
            <div sx={{width: ["100%","100%","40%"], px: 5, order: [1,1,0]}}>
              <Styled.h2 sx={{mt: [6,6,0]}}>Support</Styled.h2>
              <Styled.p sx={{fontSize: 1}}>
              The average adult head is 10-11 pounds. That's a decent amount of weight for a pillow to have to support every night and a big reason why many pillows on the market 
              today don't cut it. Our Reactiv Foam cradles support and align your head, neck, and spine helping you achieve a better night's sleep.
              </Styled.p>
        
            </div>
            <Flex sx={{width: ["100%","100%","40%"], justifyContent: "center", order: [0,0,1]}}>
              <video width="100%" height="auto" autoPlay muted loop playsInline>
                <source src={Support} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Flex>
          </Flex>
        </Container>
      </div>
      <div sx={{backgroundColor: "#fff", width: "100%", py: 6}}>
        <Container> 
          <Flex sx={{flexWrap: "wrap", justifyContent: "center",alignItems: "center", width: "100%"}}>
            <Flex sx={{width: ["100%","100%","40%"], justifyContent: "center"}}>
              <video width="100%" height="auto" autoPlay muted loop playsInline>
                <source src={Squishy} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Flex>
            <div sx={{width: ["100%","100%","40%"],px: 5}}>
              <Styled.h2 sx={{mt: [6,6,0]}}>Ventilation</Styled.h2>
              <Styled.p sx={{fontSize: 1}}>
              Memory foam tends to collect heat and not be very breathable. We fix that problem by giving our pillow proper ventilation. We take it a step further with our Quad Technology. We target different quadrants on the pillow through different sized holes enabling a combination of comfort, support, and airflow. 
              The larger holes in the center of the pillow allow your head to be cradled while the smaller holes around the perimeter create more support for your neck.
              </Styled.p>
      
            </div>
          </Flex>
        </Container>
      </div>

      <div sx={{backgroundColor: "#fff", width: "100%", py: 6}}>
        <Container> 
          <div className="yotpo yotpo-main-widget"
            data-product-id="4865571979401" 
            data-name="Reactiv Foam Pillow"
            data-domain="https://sleepnsnooze.com"
            data-url="https://sleepnsnooze.com/lp/pillow-quiz" 
            data-description="The best pillow ever">  
          </div>
        </Container>
      </div>

      <div sx={{backgroundColor: "lightGray", width: "100%", py: 6}}>
        <Container> 
          <Grid columns={[1,1,3]} gap={2}>
            <Flex sx={{flexDirection: "column", alignItems: "center", justifyContent: "center", mt: [5,5,0]}}>
              <img src={Alignment} width="55px" sx={{mb: 0}} />
              <Styled.h6 sx={{mb: 0, mt: 3}}>Promotes Alignment</Styled.h6>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center", justifyContent: "center", mt: [5,5,0]}}>
              <img src={Temperature} width="70px" sx={{mb: 0}} />
              <Styled.h6 sx={{mb: 0, mt: 3}}>Regulates Temperature</Styled.h6>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center", justifyContent: "center", mt: [5,5,0]}}>
              <img src={Comfort} width="70px" sx={{mb: 0}} />
              <Styled.h6 sx={{mb: 0, mt: 3}}>Superior Comfort</Styled.h6>
            </Flex>
          </Grid>
        </Container>
      </div>

   


      
      
      <div sx={{backgroundColor: "#b3e5fc", width: "100%", py: 6}}>
        <Container sx={{px: [5, 5, 0]}}> 
          <Grid columns={[1,1,3]} gap={5}>
            <Flex sx={{flexDirection: "column", alignItems: "center", mt:3}}>
              <div sx={{backgroundColor: "light", boxShadow: "medium", p:5}}>
                <Styled.h4 sx={{textAlign: "center", my: "0", mb: 3, color: "primary"}}>Reactiv&trade; Memory Foam</Styled.h4>
                <Styled.p sx={{fontSize: 0}}>
                  Reactiv™ Memory Foam is the best blend foam on the market. It offers the best combination of relief from pressure and head, neck, and spine support no matter your sleep position.
                </Styled.p>
              </div>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center", mt:3}}>
              <div sx={{backgroundColor: "light", boxShadow: "medium", p:5}}>
              <Styled.h4 sx={{textAlign: "center", my: "0", mb: 3, color: "primary"}}>Quad Technology&trade;</Styled.h4>
                <Styled.p sx={{fontSize: 0}}>
                Quad Technology is designed to give comfort, support, and airflow. The larger holes in the center cradle your head while the smaller holes around the perimeter support for your neck.
                </Styled.p>
              </div>
            </Flex>
            <Flex sx={{flexDirection: "column", alignItems: "center", mt:3}}>
              <div sx={{backgroundColor: "light", boxShadow: "medium", p:5}}>
              <Styled.h4 sx={{textAlign: "center", my: "0", mb: 3, color: "primary"}}>Washable Tencel&reg; Cover</Styled.h4>
                <Styled.p sx={{fontSize: 0}}>
                Our washable Tencel® cover effectively controls moisture providing a cool, clean cover that perfectly complements the Reactiv™ Memory Foam and Quad Technology™..
                </Styled.p>
              </div>
            </Flex>
          </Grid>
        </Container>
      </div>
    </Layout>
  )
}


export default IndexPage
